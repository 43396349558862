.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.work-experience-section {
	flex-basis: 800px; /* Same as .about-right-side */
	margin-top: 20px;
}

.work-experience-item {
	margin-bottom: 20px;
}

.work-experience-item .subtitle {
	font-size: 20px; /* Ensure this matches the subtitle size in .about-right-side */
	font-weight: bold;
}

.work-experience-item .about-description {
	font-size: 16px; /* Ensure this matches the description size in .about-right-side */
	color: #333;
}
.company-logo {
	width: 30px; /* Adjust size as needed */
	height: 30px; /* Adjust size as needed */
	margin-right: 10px; /* Space between logo and company name */
}


/* Responsive adjustments (if necessary) */
@media (max-width: 768px) {
	.about-container {
		padding-top: 80px; /* Adjust based on your design */
	}
	
	.about-left-side, .about-right-side, .work-experience-section {
		flex-basis: 100%;
	}
	
	.about-socials-mobile {
		display: block;
	}
}
